<template>
  <div coach-sales-history>
    <div class="inner">
      <div class="top-wrap">
        <button class="btn-prev" @click="prevBtnHandler">
          <img src="/img/icon/icon-arrow-down-dark.svg">
        </button>
        <NavTab v-model="_navTabType" :items="navTabItems" class="nav-tab" />
      </div>
      <div class="contents-wrap">
        <CoachInfoBanner>
          <a href="https://help.lvup.gg/hc/ko/sections/4410790201497-%ED%8C%90%EB%A7%A4-%EA%B0%80%EC%9D%B4%EB%93%9C" target="_blank"> {{ $t('_.infoBanner') }}
            <svg-arrow />
          </a>
        </CoachInfoBanner>
        <div class="filter-wrap">
          <div class="filter-item">
            <div class="search-filter">
              <div class="search-input-holder">
                <ValidationObserver ref="validator">
                  <ValidationProvider v-slot="{errors}" name="search" rules="lengthMin:2">
                    <CoachSearchInput v-model="searchInput" :hasError="!!errors.length" :placeHolder="$t('_.searchPlaceholder')" @search="searchBtnHandler" />
                    <ValidationErrors :errors="errors" />
                  </ValidationProvider>
                </ValidationObserver>
              </div>
              <ColorButton class="search-btn" type="dark" @click="searchBtnHandler">{{ $t('coaching.MC_SEARCH') }}</ColorButton>
            </div>
          </div>
          <div class="filter-item">
            <CoachDropdown v-model="_dateFilterValue" :list="dateFilterList" :subLabel="$t('_.dateFilterLabel')" class="order-date-filter" />
          </div>
          <div class="filter-item">
            <CoachDateDurationPicker ref="durationPicker" v-model="_duration" class="date-duration-filter" endPickerPickerSelectedLabel="종료일" startPickerSelectedLabel="시작일" />
          </div>
          <div class="filter-item">
            <CoachDropdown v-model="_statusFilterValue" :list="statusFilterList" :subLabel="$t('_.statusFilterLabel')" class="order-status-filter" />
          </div>
        </div>
        <div class="history-wrap">
          <table-control :total-count="resultCount" :selected-count="selectedRowKeys.length">
            <a @click="isNotAvailableDownloadAll ? '' : download('all')">
              <ButtonDownload class="download-btn" :disabled="isNotAvailableDownloadAll">
                <span v-if="matchedMediaDevice === 'M'">전체 목록</span>
                <span v-else>전체 목록 내려 받기</span>
              </ButtonDownload>
            </a> <a @click="isNotAvailableDownloadSelected ? '' : download('selected')">
            <ButtonDownload class="download-btn" :disabled="isNotAvailableDownloadSelected">
              <span v-if="matchedMediaDevice === 'M'">선택 목록</span>
              <span v-else>선택 목록 내려 받기</span>
            </ButtonDownload>
          </a>
          </table-control>

          <div class="history">
            <CoTable ref="table" :loading="false" checkbox :value="salesLessonOrderHistories" rowKey="lessonProductOrderId" @select="onSelect" @select-all="onSelectAll" @deselect-all="deselectAll" @sort="sortHandler">
              <TableColumn key="a" :width="120" align="center" field="orderDateTime" sort="" title="주문 완료일" />
              <TableColumn key="b" :render="renderMemo" :width="60" align="center" field="memo" title="주문 메모" />
              <TableColumn key="c" :render="renderStatus" :width="70" align="center" field="status" title="전환 상태" />
              <TableColumn key="d" :width="100" field="price" sort="" title="주문 포인트" />
              <TableColumn key="e" :width="200" field="name" title="상품명" />
              <TableColumn key="f" :width="120" field="customerNickname" title="고객 닉네임" />
              <TableColumn key="g" :width="120" field="orderNumber" title="주문 번호" />
              <TableColumn key="h" :render="renderDetailBtn" :width="70" align="center" title="상세 정보" />
            </CoTable>
            <NoContent v-if="!hasHistory" class="no-content" />
          </div>
          <div v-if="hasHistory" class="pagination-wrap">
            <Pagination v-if="!loading" :chunkSize="size" :info="_salesLessonOrderList" :showSize="10" class="pagination" theme="coaching" @change="changeCursor" />
            <Selector :list="pageSizeList" :value="size" class="page-selector" theme="white-reverse" @change="changeSize" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { commaDecimal } from 'shared/utils/numberUtils';
import ColorButton from 'shared/components/common/ColorButton.vue';
import cursorFetcher from 'shared/mixins/cursorFetcher';
import { downloadFile } from '@shared/utils/fileUtils';
import NavTab from '@/views/components/coaching/question/NavTab.vue';
import CoachInfoBanner from '@/views/components/coaching/CoachInfoBanner.vue';
import SvgArrow from '@/views/graphics/svg-arrow.vue';
import CoachDropdown from '@/views/components/coaching/CoachDropdown.vue';
import CoachDateDurationPicker from '@/views/components/coaching/CoachDateDurationPicker.vue';
import TableColumn from '@/views/components/coaching/sales/TableColumn.vue';
import TableControl from '@/views/components/coaching/sales/TableControl.vue';
import StatusBadgeSales from '@/views/components/coaching/sales/StatusBadgeSales.vue';
import CellTooltip from '@/views/components/coaching/sales/CellTooltip.vue';
import ButtonDownload from '@/views/components/coaching/ButtonDownload.vue';
import ButtonTableDetail from '@/views/components/coaching/sales/ButtonTableDetail.vue';
import Pagination from '@/views/components/common/Pagination.vue';
import Selector from '@/views/components/coaching/Selector.vue';
import NoContent from '@/views/components/coaching/sales/NoContent.vue';
import SvgMemo from '@/views/graphics/svg-memo.vue';
import CoachSearchInput from '@/views/components/coaching/CoachSearchInput.vue';
import CoTable from '@/views/components/coaching/sales/CoTable.vue';

moment.tz.setDefault('Asia/Seoul');

export default {
  name: 'CoachSalesHistory',
  lexicon: 'coaching.salesHistory',
  components: { CoachSearchInput, NoContent, Selector, Pagination, ButtonDownload, TableControl, CoachDateDurationPicker, CoachDropdown, SvgArrow, CoachInfoBanner, NavTab, ColorButton, TableColumn, CoTable },
  mixins: [cursorFetcher({
    salesLessonOrderList: async ({ services, store, cursor, size, startTimestamp, endTimestamp, status, search, sortKey, sortDirection }) => {
      const params = { cursor, size };
      const qList = [];
      let orderQuery = '';

      if (startTimestamp && endTimestamp) {
        const dateQuery = `orderDateTime GT ${startTimestamp} and orderDateTime LT ${endTimestamp}`;
        qList.push(dateQuery);
      }

      if (status && status !== 'ALL') {
        let statusQuery = '';
        if (status === 'CANCEL') {
          statusQuery = 'status in CANCEL,REFUNDED';
        } else statusQuery = `status eq ${status}`;
        qList.push(statusQuery);
      }

      if (search) {
        const searchQuery = `search like ${search}`;
        qList.push(searchQuery);
      }

      if (sortKey && sortDirection) {
        orderQuery = `${sortKey} ${sortDirection}`;
        params.order = orderQuery;
      }

      const q = qList.join(' and ');
      if (store?.commit) store.commit('info/setPreviousQuery', q);

      if (q) params.q = q;
      if (store?.commit) store.commit('ui/setLoading', true);

      const result = await services.coaching.getSalesLessonOrderList(params);
      if (store?.commit) store.commit('ui/setLoading', false);
      return result;
    },
  })],
  data: () => ({
    navTabType: 'salesHistory',
    searchInput: '',
    dateFilterValue: 'months-3',
    statusFilterValue: 'ALL',
    salesLessonOrderList: { items: [] },
    duration: { startDate: '2001-01-01', endDate: '2001-01-01' },
    hasCursorChanged: false,
    selectedRowKeys: [],
  }),
  watch: {
    salesLessonOrderList() {
      /** cursor 변경 시, pagination에 scroll 맞추도록 하는 기능 */
      this.$refs.table.deselectedAll();
      if (this.hasCursorChanged) {
        setTimeout(() => {
          const el = this.$el;
          if (!el) return;
          this.$scroll.scrollTo(el.scrollHeight - window.innerHeight);
        }, 0);
        this.hasCursorChanged = false;
      }
    },
  },
  computed: {
    loading() {
      return this.$store?.state?.ui?.loading ?? false;
    },
    navTabItems() {
      return [{ value: 'salesHistory', text: this.$t('_.title') }, { value: 'settlementHistory', text: this.$t('coaching.settlementHistory.title') }];
    },
    _navTabType: {
      get() {
        return this.navTabType;
      },
      set(v) {
        if (v === 'settlementHistory') {
          this.$router.push({ name: 'CoachSettlementHistory' });
        }
      },
    },
    startTimestamp() {
      return this.$route.query?.startTimestamp;
    },
    endTimestamp() {
      return this.$route.query?.endTimestamp;
    },
    size: {
      get() {
        if (this.$route.query?.size) return Number(this.$route.query?.size);
        return 10;
      },
      set(v) {
        const query = { ...this.$route.query, size: v, cursor: 0 };
        this.$router.push({ query });
      },
    },
    cursor: {
      get() {
        return this.$route.query?.cursor ?? 0;
      },
      set(v) {
        this.hasCursorChanged = true;
        const query = { ...this.$route.query, cursor: v };
        this.$router.push({ query });
      },
    },
    sortKey: {
      get() {
        return this.$route.query?.sortKey || '';
      },
      set(v) {
        const query = { ...this.$route.query, sortKey: v };
        this.$router.push({ query });
      },
    },
    dateFilterList() {
      const durationList = [{ months: 3 }, { months: 6 }, { months: 12 }].map(item => {
        const [unit, amount] = Object.entries(item)[0];
        const labelStr = this.$duration(item);
        const value = [unit, amount].join('-');
        return { label: this.$t('_.filterRecent', [labelStr]), value };
      });
      return [...durationList, { value: 'EDIT', label: this.$t('_.filterDateEdit') }];
    },
    _dateFilterValue: {
      get() {
        return this.dateFilterValue;
      },
      set(v) {
        this.dateFilterValue = v;
        this.setDateFilter(v);
      },
    },
    _statusFilterValue: {
      get() {
        return this.$route.query?.status ?? this.statusFilterValue;
      },
      set(v) {
        this.statusFilterValue = v;
        this.setStatusFilter(v);
      },
    },
    _duration: {
      get() {
        return this.duration;
      },
      set(v) {
        this.dateFilterValue = 'EDIT';
        this.duration = v;
        this.setDurationFilter(v);
      },
    },
    statusFilterList() {
      const statusList = ['ALL', 'WAIT', 'ONGOING', 'END', 'SETTLED', 'CANCEL'];
      const list = statusList.map(item => ({ label: this.$t(`_.status[${item}]`), value: item }));
      return list;
    },
    pageSizeList() {
      const pageList = [100, 50, 25, 10];
      const list = [];
      for (let i = 0; i < 4; i++) {
        list.push({ label: `${pageList[i]}건 보기`, value: pageList[i] });
      }
      return list;
    },
    _salesLessonOrderList() {
      return /** @type{SalesLessonOrderList} */ this.salesLessonOrderList;
    },
    hasHistory() {
      return this._salesLessonOrderList?.totalCount;
    },
    salesLessonOrderHistories() {
      return this._salesLessonOrderList?.items?.map(item => {
        const orderDateTime = this.$date(item.orderDateTime, 'datetimeNoneMeridiem');
        const price = `${commaDecimal(item?.price)} UP`;
        return { lessonProductOrderId: item?.productOrderId, orderDateTime, memo: item?.memo, status: item?.status, price, name: item?.name, customerNickname: item?.customer?.nickname, orderNumber: item?.orderNumber };
      });
    },
    resultCount() {
      return this._salesLessonOrderList?.totalCount;
    },
    isNotAvailableDownloadAll() {
      return this.resultCount < 1;
    },
    isNotAvailableDownloadSelected() {
      return this.selectedRowKeys.length < 1;
    },
  },
  methods: {
    prevBtnHandler() {
      this.$router.push({ name: 'TutorHome' });
    },
    searchBtnHandler() {
      if (this.searchInput.length === 1) return;
      this.setSearchQuery();
    },
    cancelBtnHandler() {
      this.searchInput = '';
      this.setSearchQuery();
    },
    setSearchQuery() {
      const query = { ...this.$route.query, search: encodeURI(this.searchInput), cursor: 0 };
      this.$router.push({ query });
    },
    setDateFilter(filterItem) {
      const format = 'YYYY-MM-DD';
      let startDate;
      switch (filterItem) {
        case 'months-3': {
          startDate = moment().subtract(3, 'month');
          break;
        }
        case 'months-6': {
          /** 6 month */
          startDate = moment().subtract(6, 'month');
          break;
        }
        case 'months-12': {
          /** 12 month */
          startDate = moment().subtract(12, 'month');
          break;
        }
        case 'EDIT': {
          const el = this.$refs.durationPicker;
          el?.showStartDateCalendar();
          return;
        }
        default: {
          return;
        }
      }
      const endDate = moment();
      this.duration = { startDate: startDate.format(format), endDate: endDate.format(format) };
      const startTimestamp = startDate.valueOf();
      const endTimestamp = moment(`${endDate.format(format)} 23:59`).valueOf();

      const query = { ...this.$route.query, startTimestamp, endTimestamp, cursor: 0 };
      this.$router.push({ query });
    },
    setDurationFilter(duration) {
      const { startDate, endDate } = duration;
      const startTimestamp = moment(startDate).valueOf();
      const endTimestamp = moment(`${endDate} 23:59`).valueOf();
      const query = { ...this.$route.query, startTimestamp, endTimestamp, cursor: 0 };
      this.$router.push({ query });
    },
    setStatusFilter(status) {
      const query = { ...this.$route.query, status, cursor: 0 };
      this.$router.push({ query });
    },
    renderMemo({ row }, h) {
      if (row.memo) {
        return h(CellTooltip, { props: { text: row.memo.description } });
      }
      return h(SvgMemo);
    },
    renderStatus({ row }, h) {
      return h(StatusBadgeSales, { props: { value: row?.status } });
    },
    renderDetailBtn({ row }, h) {
      return h(ButtonTableDetail, { on: { click: this.detailBtnClickHandler.bind(this, row) } });
    },
    changeSize(size) {
      if (size === this.size) return;
      this.size = size;
    },
    changeCursor(cursor) {
      if (cursor === this.cursor) return;
      this.cursor = cursor;
    },
    detailBtnClickHandler(row) {
      const { lessonProductOrderId } = row;
      this.$router.push({ name: 'CoachSalesHistoryDetail', params: { lessonProductOrderId } });
    },
    onSelect({ selectedRowKeys }) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onSelectAll({ selectedRowKeys }) {
      this.selectedRowKeys = selectedRowKeys;
    },
    deselectAll() {
      this.selectedRowKeys = [];
    },
    sortHandler(params) {
      let sortKey = '';
      let sortDirection = '';

      Object.entries(params).forEach(item => {
        if (item?.[1]) {
          [sortKey, sortDirection] = item;
        }
      });

      const query = { ...this.$route.query, sortKey, sortDirection };
      this.$router.push({ query });
    },
    async download(type) {
      let q = this.$store.state?.info?.previousQuery;
      let order = 'orderDateTime desc';
      let result = {};

      if (this.$route.query.sortKey && this.$route.query.sortDirection) {
        order = [this.$route.query.sortKey, this.$route.query.sortDirection].join(' ');
      }

      if (this.$store) this.$store.commit('ui/setLoading', true);
      if (type === 'all') {
        result = await this.$services.coaching.downloadSalesLessonOrderList({ order, q });
      } else {
        q = `${q} and productOrderId in ${this.selectedRowKeys.join(',')}`;
        result = await this.$services.coaching.downloadSalesLessonOrderList({ order, q });
      }
      if (this.$store) this.$store.commit('ui/setLoading', false);

      downloadFile(result);
    },
  },
  mounted() {
    if (this.$route.query?.startTimestamp && this.$route.query?.endTimestamp) this.dateFilterValue = 'EDIT';

    const startTimestamp = this.$route.query?.startTimestamp || moment().subtract(3, 'month').valueOf();
    const endTimestamp = this.$route.query?.endTimestamp || moment().valueOf();
    const format = 'YYYY-MM-DD';
    const startDate = moment(Number(startTimestamp)).format(format);
    const endDate = moment(Number(endTimestamp)).format(format);
    this.duration = { startDate, endDate };
  },
  async beforeRouteEnter(to, from, next) {
    const vm = await new Promise((r => next(vm => r(vm))));

    if (from?.name === 'CoachSalesHistoryDetail') {
      await vm.resetFetch(vm.salesLessonOrderList);
    }
  },
  async asyncData({ route, mixinFetcher, services, store }) {
    const { status, search, sortKey, sortDirection, cursor, size } = route.query;
    /** TODO: 서버의 현재 시간과 client의 시간 고려하여 기간 필터에 따라 데이터 필터링 하도록 하는 기능 필요. (현재는 서버의 시간 기준으로 3달) */
    const startTimestamp = route.query?.startTimestamp || moment().subtract(3, 'month').valueOf();
    const endTimestamp = route.query?.endTimestamp || moment().valueOf();
    return { cursor, size, ...await mixinFetcher.fetch({ store, route, services, defaultParams: { startTimestamp, endTimestamp, status, search, sortKey, sortDirection, cursor, size } }) };
  },
};
</script>
<style lang="less">
@import '~@/less/coaching.less';

[coach-sales-history] { .bgc(#fff);
  .top-wrap { .max-w(1260); .m(0, auto); .pt(40); .mb(32);
    .btn-prev { .flex; .w(40); .h(40); transform: rotate(90deg); .bgc(#ebebf0); .br(12);
      &:hover {
        > img { .o(0.8);}
      }
      > img { .m(auto); .o(0.4);}
    }
    .nav-tab {.mt(46); border-bottom: 1px solid #d8d8d8; }
  }

  .contents-wrap {.max-w(1260); .m(0, auto); .pb(130);
    .filter-wrap { .flex; .mt(32); gap: 16px;
      .search-filter { .flex;
        .search-input-holder { .flex; .rel; .mr(8); .w(100%); .h(44);
          > fieldset {.w(100%); }
        }
        [validation-errors] {.c(#f00); .fs(12);}
        .search-btn { .w(66); .h(44); .br(8); }
      }
      .order-date-filter { .h(44);
        &[coach-dropdown] > div.open > ul {.max-h(unset); .h(fit-content); overflow: visible}
        > div { .h(inherit);
          > a {.h(44); }
        }
      }
      .order-status-filter { .h(44);
        &[coach-dropdown] > div.open > ul {.max-h(unset); .h(fit-content); overflow: visible}
        > div { .h(inherit);
          > a {.h(44); }
        }
      }
      .date-duration-filter { .w(100%);
        .date-picker {.w(100%);
          .picker-btn {.w(100%);}
        }
      }

      .filter-item {
        &:nth-of-type(1) { flex-basis: 30%; }
        &:nth-of-type(2) { flex-basis: 20%; }
        &:nth-of-type(3) { flex-basis: 30%; }
        &:nth-of-type(4) { flex-basis: 20%; }
      }
    }
    .history-wrap {.mt(32);
      .download-btn {
        &:first-of-type {.mr(8);}
      }
      .history {
        [custom-table] {.mt(20); overflow-x: scroll;
          .ve-table-container {.min-w(1075);
            thead > .ve-table-header-tr {
              th:nth-of-type(2) {.flex; .h(45); .pr(45); .items-center; .justify-center; flex-direction: row-reverse;}
              .ve-table-sort { .mr(4); }

            }
            tbody > .ve-table-body-tr {
              td:nth-of-type(5) { .pl(10);}
            }
          }
          [svg-memo] { cursor: not-allowed; }
        }
      }
      .pagination-wrap { .rel; .flex; .mt(20); .justify-center;
        .page-selector { .abs; .r(0); .t(0); }
      }
    }
  }

  @media (@ds-down) {
    .inner { .p(0, 58); }
  }

  @media (@tl-down) {
    .inner { .p(0, 20);}
    .contents-wrap { .pb(164);
      .filter-wrap { flex-wrap: wrap;
        .filter-item {
          &:nth-of-type(1) { flex-basis: 100%; }
          &:nth-of-type(2) { flex-basis: calc(30% - 8px); }
          &:nth-of-type(3) { flex-basis: calc(40% - 16px); }
          &:nth-of-type(4) { flex-basis: calc(30% - 8px); }
        }
      }
    }
  }

  @media (@tp-down) {
    .contents-wrap {
      .filter-wrap { flex-wrap: wrap;
        .filter-item {
          &:nth-of-type(1) { flex-basis: 100%; }
          &:nth-of-type(2) { flex-basis: 100%; .mt(4); }
          &:nth-of-type(3) { flex-basis: 100%; }
          &:nth-of-type(4) { flex-basis: 100%; }
        }
      }
      > .history-wrap > .pagination-wrap { display: inherit;
        .page-selector {.w(130); float: right; position: inherit; .mt(20);}
      }
    }
  }

  .no-content {.min-h(300);}
}

</style>
