<template>
  <div coach-date-duration-picker>
    <CoachDatePicker ref="startPicker" v-model="startDate" :emphasis="startPickerEmphasis" :rangeEnd="endDate" :selectedLabel="startPickerSelectedLabel" class="date-picker start-date" @triggeredShowCalendar="triggeredStartDateCalendar" />
    <div class="divider"></div>
    <CoachDatePicker ref="endPicker" v-model="endDate" :emphasis="endPickerEmphasis" :rangeStart="startDate" :selectedLabel="endPickerPickerSelectedLabel" class="date-picker end-date" @triggeredShowCalendar="triggeredEndDateCalendar" />
  </div>
</template>

<script>
import CoachDatePicker from '@/views/components/coaching/CoachDatePicker.vue';

export default {
  name: 'CoachDateDurationPicker',
  components: { CoachDatePicker },
  props: {
    value: {
      type: Object,
      default: () => ({ startDate: '2001-01-01', endDate: '2001-01-01' }),
    },
    startPickerSelectedLabel: String,
    endPickerPickerSelectedLabel: String,
  },
  computed: {
    duration() {
      return this.value;
    },
    startDate: {
      get() {
        return this.duration?.startDate;
      },
      set(dateStr) {
        if (dateStr > this.endDate) return;
        this.$refs?.endPicker?.pickerClickHandler();
        this.$emit('input', { startDate: dateStr, endDate: this.endDate });
      },
    },
    endDate: {
      get() {
        return this.duration?.endDate;
      },
      set(dateStr) {
        if (dateStr < this.startDate) return;
        this.$emit('input', { startDate: this.startDate, endDate: dateStr });
      },
    },
    startPickerEmphasis() {
      return this.endDate;
    },
    endPickerEmphasis() {
      return this.startDate;
    },
  },
  methods: {
    showStartDateCalendar() {
      this.$refs?.startPicker?.pickerClickHandler();
    },
    triggeredStartDateCalendar() {
      this.$refs?.endPicker?.hideCalendar();
    },
    triggeredEndDateCalendar() {
      this.$refs?.startPicker?.hideCalendar();
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';

[coach-date-duration-picker] { .flex;
  .divider { .w(8); .h(1); .bgc(#000); align-self: center; .m(0, 4); }
  @media (@ml-down) {
    .rel;
    .date-picker {
      position: unset;
    }
  }

}
</style>
