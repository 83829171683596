<script>
import StatusBadge from './StatusBadge.vue';

export default {
  extends: StatusBadge,
  computed: {
    badgeData() {
      return {
        WAIT: ['gray', '이용 대기'],
        ONGOING: ['blue', '이용 준비중'],
        REFUNDED: ['red', '구매 취소'],
        END: ['green', '이용 완료'],
        SETTLED: ['purple', '구매 확정'],
        CANCEL: ['red', '구매 취소']
      };
    },
    colorClass() {
      return this.badgeData[this.value]?.[0] ?? 'green';
    },
    statusText() {
      return this.badgeData[this.value]?.[1] ?? '-';
    }
  }
};
</script>