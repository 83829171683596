<template>
  <button cell-tooltip v-popover.bottom.right="{text, theme:'coaching', maxWidth: 200, translate: false}" aria-label="tooltip" />
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '-',
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[cell-tooltip] {.bg('/img/coaching/tooltip-outline.svg');.wh(20, 19.5);border: none;.bgc(transparent);.mt(7);
  &:hover {.bg('/img/coaching/tooltip-fill.svg')}
}
</style>
