<template>
  <div :class="{'disable': disable}" coach-date-picker>
    <button :class="{'active': showCalendar }" class="picker-btn" @click="pickerClickHandler">
      <svg-note />
      <span>{{ date }}</span>
    </button>
    <DateSelector v-if="showCalendar" v-model="date" :emphasis="emphasis" :rangeEnd="rangeEnd" :rangeStart="rangeStart" :selectedLabel="selectedLabel" class="date-selector" @select="selectHandler" />
  </div>
</template>

<script>
import DateSelector from '@/views/components/common/date/DateSelector.vue';
import SvgNote from '@/views/graphics/svg-note.vue';

export default {
  name: 'CoachDatePicker',
  components: { SvgNote, DateSelector },
  props: {
    value: { type: String, default: '2000-01-01' },
    rangeStart: { type: String },
    rangeEnd: { type: String },
    disable: { type: Boolean, default: false },
    emphasis: { type: String },
    selectedLabel: String,
  },
  data: () => ({
    showCalendar: false,
  }),
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(dateStr) {
        this.$emit('input', dateStr);
      },
    },
  },
  methods: {
    hideCalendar() {
      this.showCalendar = false;
    },
    selectHandler() {
      this.hideCalendar();
    },
    pickerClickHandler() {
      this.showCalendar = !this.showCalendar;
      if (this.showCalendar) this.$emit('triggeredShowCalendar');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-date-picker] { .rel;
  &.disable {
    .picker-btn {border: solid #d5d5de 1px; .br(8); pointer-events: none; .bgc(#f2f2f4);
      > span {.c(#a4a3ae);}
    }
  }

  .picker-btn { .flex; .p(16); .h(44); .bgc(#fff); border: solid #d5d5de 1px; .br(8); .items-center; .c(#191919); .fs(14); .pointer;
    &:hover {border: solid #191919 1px;}
    &.active { border: solid #191919 1px;}
    > span {.ml(6);}
  }
  .date-selector { .abs; .l(0); .t(52); .z(1); .w(375); .bgc(#fff); .p(20); border: 1px solid rgba(0, 0, 0, 0.1); filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1)); .br(20); box-sizing: border-box;
    .date-selector-header { .h(unset); .lh(unset); .mb(30);
      button {.p(0); .bgc(#fff); border: none; .t(unset);
        > svg .stroke-target {stroke: #a4a3ae; }
      }
    }
    [calendar] tbody td {
      &.today > span { text-decoration: underline; }
    }
  }
}

</style>
