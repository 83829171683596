<template>
  <div date-selector :class="theme">
    <div v-if="m" class="date-selector-header">
      <button @click="move(-1, 'year')">
        <svg-double-angle />
      </button>
      <button @click="move(-1, 'month')">
        <svg-angle />
      </button>
      <span>{{ m.format('YYYY년 MM월') }}</span>
      <button @click="move(1, 'month')">
        <svg-angle />
      </button>
      <button @click="move(1, 'year')">
        <svg-double-angle />
      </button>
    </div>
    <Calendar :month="month" :selected="value" :selectedLabel="selectedLabel" :range-start="rangeStart" :range-end="rangeEnd" @select="onSelect" :theme="theme" />
  </div>
</template>

<script>
import moment from 'moment';
import ComponentModel from '@shared/mixins/ComponentModel';
import SvgAngle from '@shared/graphics/svg-angle.vue';
import Calendar from '@/views/components/common/date/Calendar.vue';
import SvgDoubleAngle from '@/views/graphics/svg-double-angle.vue';

export default {
  name: 'DateSelector',
  components: { SvgDoubleAngle, SvgAngle, Calendar },
  mixins: [ComponentModel({
    event: 'select',
    update: 'updateModel',
  })],
  props: {
    value: {},
    rangeStart: String,
    rangeEnd: String,
    theme: String,
    selectedLabel: String,
  },
  data() {
    return {
      selected: null,
      m: null,
      month: null,
    };
  },
  methods: {
    updateModel() {
      this.m = moment(this.value || undefined);
      if (this.value) this.selected = this.m.format('YYYY-MM-DD');
      this.m.set({ date: 1 });
      this.month = this.m.format('YYYY-MM');
    },
    move(d, unit) {
      this.m.add(d, unit);
      this.month = this.m.format('YYYY-MM');
    },
    onSelect(v) {
      this.$emit('select', v);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[date-selector] { .c(@c-title-black);
  .date-selector-header { .tc; .h(70); .fs(20, 70px); .mb(10);
    > * {.vam;}
    button {.abs; .pointer; .wh(24, 24); .t(26);
      svg { .w(24);
        .stroke-target { stroke: #191919; }
      }
      &:nth-of-type(1) {.l(24); .t-r(90deg);}
      &:nth-of-type(2) {.l(55); .t-r(90deg);}
      &:nth-of-type(3) {.r(55); .t-r(-90deg);}
      &:nth-of-type(4) {.r(24); .t-r(-90deg);}
    }
  }
  &.dark {
    .date-selector-header {
      > span { .c(white); }
      button {
        svg { .w(24);
          .stroke-target { stroke: white; }
        }
      }
    }
  }
}
</style>
